import React from "react";
import "./WinScreen.scss";
import { AnswerShoe } from "../../types";
type Props = {
  answerShoe: AnswerShoe;
};

function WinScreen({ answerShoe }: Props) {
  return (
    <div className="win">
      <h1>You Won!!!</h1>
      <img className="win__gif" src="https://media.giphy.com/media/fT1KbPjFUp77rN5VwD/giphy.gif" alt="" />

      <h4>The Correct Answer was:</h4>
      <h2 className="win__answer">
        {answerShoe.model} {answerShoe.colorway} {answerShoe.releaseYear}
      </h2>
      <p className="win__description">{answerShoe.description}</p>
    </div>
  );
}

export default WinScreen;
